<template>
  <el-container class="shopList">
    <el-header class="box add">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>库房管理</el-breadcrumb-item>
        <el-breadcrumb-item>库房信息列表</el-breadcrumb-item>
        <el-breadcrumb-item v-if="form.id">编辑库房</el-breadcrumb-item>
        <el-breadcrumb-item v-else>新增库房</el-breadcrumb-item>
      </el-breadcrumb>
      <div>
        <el-button class="back_btn" size="medium" plain @click="$router.back()"
          >返回</el-button
        >
      </div>
    </el-header>
    <el-main class="p15 pt15">
      <el-form
        status-icon
        ref="form"
        :model="form"
        label-width="100px"
        size="medium"
        :rules="formRules"
        style="text-align: left"
      >
        <el-row class="box mt20 f14">
          <el-col :span="24">
            <el-form-item label="性质：" class="w">
              <el-radio-group v-model="form.nature" @change="handleChangeType" >
                <el-radio-button class="own" label="自有"  ></el-radio-button>
                <el-radio-button class="borrow"  label="第三方"></el-radio-button>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <template v-if="form.nature === '自有'">
          <el-col :span="24" style="width: 50%">
            <el-form-item label="场地类型：" class="w"
                          prop="warehouse_type_ids">
              <el-checkbox-group v-model="warehouse_type_ids" >
                <el-checkbox-button v-for="pro in propertiesList"
                                    :label="pro.id"
                                    :key="pro.id"
                                    @change="handleCheckedProChange">{{pro.label}}
                </el-checkbox-button>
              </el-checkbox-group>
            </el-form-item>
          </el-col>
            <el-col :span="24">
              <el-form-item label="库房名称：" class="w"
                            prop="warehouse_name">
                <el-input
                  v-model="form.warehouse_name"
                  placeholder="请输入库房名称"
                  style="width: 30%"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="监管仓：" class="w">
              <el-radio v-model="form.supervise_type" label="1" border size="medium">是</el-radio>
              <el-radio v-model="form.supervise_type" label="2" border size="medium">否</el-radio>
              </el-form-item>
            </el-col>
          <el-col :span="22">
            <el-row >
              <el-form-item class="w" label="所在地址：" size="medium" prop="address" >
                <el-col :span="4">
                  <el-form-item size="medium">
                    <el-select clearable v-model="form.province" placeholder="请选择省级" @change="handleChange($event,2)"
                               value-key="region_name">
                      <el-option v-for="item in provinceOptions" :key="item.region_code" :label="item.region_name"
                                 :value="item"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item size="medium">
                    <el-select clearable v-model="form.city" placeholder="请选择市级" @change="handleChange($event,3)"
                               value-key="region_name">
                      <el-option v-for="item in cityOptions" :key="item.region_code" :label="item.region_name"
                                 :value="item"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item size="medium">
                    <el-select clearable v-model="form.area" placeholder="请选择区/县" @change="handleChange($event,4)"
                               value-key="region_name">
                      <el-option v-for="item in areaOptions" :key="item.region_code" :label="item.region_name"
                                 :value="item"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item size="medium">
                    <el-select clearable v-model="form.town" placeholder="请选择街道/镇" @change="handleChange($event,5)"
                               value-key="region_name">
                      <el-option v-for="item in townOptions" :key="item.region_code" :label="item.region_name"
                                 :value="item"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item size="medium" >
                    <el-select clearable v-model="form.vill" placeholder="请选择居委会/村" value-key="region_name"
                               @change="handleChange($event,6)">
                      <el-option v-for="item in villOptions" :key="item.region_code" :label="item.region_name"
                                 :value="item"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-form-item>
            </el-row>
          </el-col>
          <el-col :span="24" class="tl" >
            <el-form-item label="详细地址：" size="medium" class="w" >
              <el-input style="width: 50%" placeholder="如道路、门牌号、小区、楼栋号、单元室等（请勿重复录入省市区县信息）" maxlength='30' clearable v-model="form.address"
                        class="input-with-select">
              </el-input>
            </el-form-item>
          </el-col>
          </template>
          <template v-else>
            <el-col :span="24">
              <el-form-item label="库方性质："
                            class="w">
                <el-radio v-model="nature" label="租赁"></el-radio>
                <el-radio v-model="nature" label="借用"></el-radio>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="库房名称："
                            class="w">
                <el-autocomplete
                  v-model="form.warehouse_name"
                  value-key="warehouse_name"
                  placeholder="请输入库房名称"
                  :fetch-suggestions="querySearchAsync"
                  @select="handleSelect"
                  style="width: 100%"
                >
                  <template slot-scope="{ item }">
                    <span class="name">{{item.warehouse_name}}</span>
                    <span class="addr" style="font-size:12px;color:silver;float: right">{{item.company_name}}</span>
                  </template>
                </el-autocomplete>
              </el-form-item>
            </el-col>
              <el-col :span="24">
                <el-form-item label="监管仓：" class="w" >
                  <el-radio-group disabled v-model="form.supervise_type">
                    <el-radio  label="1" border size="medium">是</el-radio>
                    <el-radio  label="2" border size="medium">否</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            <el-col :span="11">
              <el-form-item label="所属公司："
                            prop="company_name_rent"
                            class="w">
                <el-input
                  v-model="form.company_name_rent"
                  disabled
                  style="width: 100%"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="信用编码："
                            prop="credit_code"
                            class="w">
                <el-input
                  v-model="form.credit_code_rent"
                  disabled
                  style="width: 100%"
                ></el-input>
              </el-form-item>
            </el-col>
          </template>
            <el-col :span="11" class="tl">
              <el-form-item size="medium"
                            label="面积："
                            class="w"
                            prop="warehouse_area">
                <div v-if="this.form.nature==='自有'">
                  <el-input-number
                    v-model="form.warehouse_area"
                    style="width: 95%"
                    :precision="2"
                    ></el-input-number> m²
                </div>
                <div v-else >
                  <el-input-number
                    v-model="form.warehouse_area"
                    style="width: 90%"
                    :precision="2"
                    disabled  ></el-input-number>m²
                </div>
              </el-form-item>
            </el-col>
          <el-col :span="11" class="tl">
            <el-form-item size="medium" label="容积：" prop="warehouse_volume" class="w">
              <div v-if="this.form.nature==='自有'">
              <el-input-number
                v-model="form.warehouse_volume"
                style="width: 95%"
                :precision="2"
              ></el-input-number> m³
              </div>
              <div v-else>
                <el-input-number
                  v-model="form.warehouse_volume"
                  style="width: 90%"
                  :precision="2"
                  disabled
                ></el-input-number> m³
              </div>
            </el-form-item>
          </el-col>
          <template v-if="this.form.nature==='自有'">
          <el-col :span="24">
            <el-form-item label="储存环境：" class="w" prop="storage_conditions_ids">
              <el-checkbox-group v-model="storage_conditions_ids" size="small">
                <el-checkbox-button style="margin-left:10px"
                             v-for="sto in storages"
                             :label="sto.id"
                             :key="sto.id"
                             @change="handleCheckedStoChange"
                              >{{sto.lable}}
                </el-checkbox-button>
              </el-checkbox-group>
            </el-form-item>
          </el-col>
          </template>
          <el-col :span="11">
            <el-form-item label="负责人："
                          prop="warehouse_principal"
                          class="w">
              <div v-if="this.form.nature==='自有'">
                <el-input
                  v-model="form.warehouse_principal"
                  placeholder="请输入负责人姓名"
                  style="width: 100%"
                ></el-input>
              </div>
              <div v-else>
                <el-input
                  v-model="form.warehouse_principal"
                  style="width: 100%"
                  disabled
                ></el-input>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="联系电话：" prop="warehouse_mobile" class="w">
              <div v-if="this.form.nature==='自有'">
                <el-input
                  v-model="form.warehouse_mobile"
                  placeholder="请输入负责人联系电话"
                  style="width: 100%"
                ></el-input>
              </div>
              <div v-else>
                <el-input
                  v-model="form.warehouse_mobile"
                  style="width: 100%"
                  disabled
                ></el-input>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="库长姓名："
                          prop="admin_lib_name"
                          class="w">
              <div v-if="this.form.nature==='自有'">
              <el-input
                v-model="form.admin_lib_name"
                placeholder="请输入行政库长姓名"
                style="width: 100%"
              ></el-input>
              </div>
              <div v-else>
                <el-input
                  v-model="form.admin_lib_name"
                  style="width: 100%"
                  disabled
                ></el-input>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="库长职务："  class="w">
              <div v-if="this.form.nature==='自有'">
              <el-input
                v-model="form.admin_lib_job"
                placeholder="请输入行政库长职务"
                style="width: 100%"
              ></el-input>
              </div>
              <div v-else>
                <el-input
                  v-model="form.admin_lib_job"
                  style="width: 100%"
                  disabled
                ></el-input>
              </div>
            </el-form-item>
          </el-col>
          <template v-if="this.form.nature==='自有'">
          <el-col :span="11">
            <el-form-item label="代表人："
                          prop="legal_person_name"
                          class="w">
              <el-input
                v-model="form.legal_person_name"
                placeholder="请输入法定代表人姓名"
                style="width: 100%"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="联系电话：" prop="legal_person_mobilephone"  class="w">
              <el-input
                v-model="form.legal_person_mobilephone"
                placeholder="请输入法定代表人电话"
                style="width: 100%"
              ></el-input>
            </el-form-item>
          </el-col>
          </template>
          <el-col :span="20" class="tc">
            <el-button
              class="ml_5"
              size="medium"
              type="primary"
              @click="submitForm('form')"
              >提交</el-button
            >
          </el-col>
        </el-row>
      </el-form>
    </el-main>
  </el-container>
</template>
<script>
import axios from "@/util/api";
import { local, aa, objKeySort } from "@/util/util";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";

export default {
  data() {
    //校验面积
    var checkArea = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('面积/容积不能为空'));
      }
      setTimeout(() => {
        if (!Number.isInteger(value)) {
          callback(new Error('请输入数字值'));
        } else {
          if (value < 0) {
            callback(new Error('面积/容积必须大于0'));
          } else {
            callback();
          }
        }
      }, 1000);
    };
    //校验场地类型
    var checkType = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('至少选一个！'));
      }
      setTimeout(() => {
          if (value.length> 0) {
            callback();
          } else {
            callback(new Error('至少选一个！'));
          }
      }, 1000);
    };
    return {
      count:0,//用于场地类型计数
      num:0,//用于存储环境计数
      loading: false,
      show: true,
      keyword:'',
      nature:'',
      form: {
        nature: '自有',
        warehouse_name:'', //库房名称
        warehouse_type_ids:"", //场地类型id
        warehouse_type_names:"",//场地类型
        warehouse_area:'', //面积
        warehouse_volume:'',   //容积
        warehouse_principal:'',    //负责人
        warehouse_mobile:'', ///负责人联系电话
        province:"", //省
        city:'',//市
        area:'',//县
        town:'',//镇
        vill:'',//村
        address:'',//详细地址
        admin_lib_name:'', //库长姓名
        admin_lib_job:'', //库长职务
        legal_person_name:'', //代表人姓名
        legal_person_mobilephone:'', //代表人电话
        storage_conditions_ids:"", //环境id
        storage_conditions_names:"", //存储环境
        supervise_type:'',//是否是监管仓
        //租用的信息
        warehouse_id:'',//库房id
        company_id_rent:'',//公司id
        company_name_rent:'',//公司名称
        credit_code_rent: "",  //社会编码
      },
      //多选框
      warehouse_type_ids:[], //场地类型id
      warehouse_type_names:[],//场地类型
      //多选框的数组     存储环境
      storage_conditions_ids:[],
      storage_conditions_names:[],
      provinceFlag: false,
      cityFlag: false,
      areaFlag: false,
      townFlag: false,
      villFlag: false,
      provinceOptions: [],
      cityOptions: [],
      areaOptions: [],
      townOptions: [],
      villOptions: [],
      //场地性质 现在是固定的 应该要从数据库中读取
      propertiesList:[], //场地类型
      //存储环境
      storages:[
        {id:"10001",lable:"常温"},
        {id:"10002",lable:"冷冻"},
        {id:"10003",lable:"冷藏"}],
      dialogVisible: false,
      dialogImageUrl: "",
      uploadDisabled: false,
      fileList: [],
      dialogVisable:false,
      //校验规则
      formRules: {
        warehouse_type_ids:[{required: true,validator: checkType, message: '请选择场地性质',trigger: 'change'}],
        warehouse_name:[{required: true, message: '请输入库房名称', trigger: 'blur' }],
        address:[{required: true, message: '请选择所在地址', trigger: ['blur','change'] }],
        warehouse_area:[{required: true, validator: checkArea, message: '请输入库房面积',trigger: 'blur' }],
        warehouse_volume:[{required: true, validator: checkArea, message: '请输入库房容积', trigger: 'blur' }],
        warehouse_principal:[{required: true, message: '请输入负责人姓名', trigger: 'blur' }],
        warehouse_mobile:[{required: true, message: '请输入负责人电话', trigger:'blur'},{min:11,max:11,pattern:/^1[34578]\d{9}$/, message: "手机号格式错误！"}],
        legal_person_name:[{required: true, message: '请输入代表人姓名', trigger: 'blur' }],
        legal_person_mobilephone:[{required: true, message: '请输入代表人电话', trigger: 'blur' },{min:11,max:11,pattern:/^1[34578]\d{9}$/,message: "手机号格式错误！"}],
        storage_conditions_ids:[{required: true,validator: checkType, message: '请选择存储环境', trigger: 'blur'}],
      }
    };
  },
   async mounted() {
     var that = this;
     this.form.id = this.$route.query.warehouse_id
       ? String(this.$route.query.warehouse_id)
       : "";
     this.form.nature = this.$route.query.nature
       ? String(this.$route.query.nature)
       : "自有";
     this.company_id = this.$route.query.company_id
       ? String(this.$route.query.company_id)
       : "";
     //加载场地性质类型
      this.getTheVenueType();
     this.getData({id: this.form.id, nature: this.form.nature});
     //加载省份信息
     this.provinceOptions = await this.fetchRegionData(1);
   },

  methods: {
    getData(params) {
      var that = this;
      if (this.form.id) {
        axios.get("/pc/company-warehouse/single", params).then((v) => {
          if (params.nature == "自有") {
            v = v.data.warehouse;
            this.json2Form(v);//json数据转化为表单中的数据
            this.form.id = this.$route.query.warehouse_id;
            this.form.supervise_type = this.form.supervise_type.toString();
            this.form.nature = '自有';
            this.form.supervise_type = v.supervise_type;
        }else{
            v = v.data.rent_car;
            this.form.warehouse_name = v.warehouse_name;
            this.form.company_name_rent = v.company_name_rent;
            this.form.credit_code_rent = v.credit_code_rent;
            this.nature = params.nature; //设置性质
            this.form.nature = '第三方';
            //这是编辑跳转过来查询第三方仓库的相关信息 根据仓库名字
            axios
              .get("/pc/company-warehouse/findByWarehouseName", { warehouse_name: v.warehouse_name })
              .then((response) => {
                let results = response.data.warehouse_list; //获取根据仓库名查询的仓库数据 这里是一个数组
                for(let i=0;i<results.length;i++){
                  if (results[i].company_name = v.company_name_rent){  //根据公司的名字来比较选择哪一个公司的信息
                    this.handleSelect(results[i]); //默认获取返回来的第一个
                  }
                }
              });
          }
        });
      }
    },
    //根据库房名称查询信息
    querySearchAsync(queryString, cb) {
      if (queryString) {
        axios
          .get("/pc/company-warehouse/findByWarehouseName", { warehouse_name: queryString })
          .then((response) => {
            let results = response.data.warehouse_list;
            cb(results);
          });
      } else {
        cb([]);
      }
    },
    handleSelect(item) {
      this.json2Form(item);
      this.form.nature = '第三方';
      this.form.supervise_type = item.supervise_type.toString();
      this.form.company_id_rent = item.company_id;
      this.form.company_name_rent = item.company_name;
      this.form.credit_code_rent = item.credit_code;
      this.form.warehouse_type_names = item.warehouse_type_names;
      this.form.storage_conditions_names = item.storage_conditions_names;
    },
    //选择性质
    handleChangeType(){
      if(!this.form.id){
        if (this.form.nature === '自有'){
           this.form = {
             nature: '自有',
             warehouse_name:'', //库房名称
             warehouse_type_ids:"", //场地类型id
             warehouse_type_names:"",//场地类型
             warehouse_area:'', //面积
             warehouse_volume:'',   //容积
             warehouse_principal:'',    //负责人
             warehouse_mobile:'', ///负责人联系电话
             province:"", //省
             city:'',//市
             area:'',//县
             town:'',//镇
             vill:'',//村
             address:'',//详细地址
             admin_lib_name:'', //库长姓名
             admin_lib_job:'', //库长职务
             legal_person_name:'', //代表人姓名
             legal_person_mobilephone:'', //代表人电话
             storage_conditions_ids:"", //环境id
             storage_conditions_names:"", //存储环境
             supervise_type:'1',//是否是监管仓
             //租用的信息
             warehouse_id:'',//库房id
             company_id_rent:'',//公司id
             company_name_rent:'',//公司名称
             credit_code_rent: "",  //社会编码
           }
          this.warehouse_type_ids = [];
           this.storage_conditions_ids = [];
        }
      }
    },
    submitForm(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          if (this.form.nature ==='第三方'){
            this.form.nature = this.nature;
          };
          if (this.form.id) {
            axios.put("/pc/company-warehouse/update", this.form).then((v) => {
              this.$router.back();
            });
          } else {
            if (this.form.nature==='自有')
              this.array2String();//将自有仓库时数组进行相关操作
            axios.post("/pc/company-warehouse/save", this.form).then((v) => {
              this.$router.back();
            });
          }
        }
      });
    },
    //选择城市
    async handleChange (item, type) {
      let regionData = await this.fetchRegionData(type, item.region_code);
      switch (type) {
        case 2:
          this.cityOptions = regionData;
          this.form.province = item.region_name;
          this.areaOptions = [];
          this.townOptions = [];
          this.villOptions = [];
          this.form.city = "";
          this.form.area = "";
          this.form.town = "";
          this.form.vill = "";
          break;
        case 3:
          this.areaOptions = regionData;
          this.form.city = item.region_name;
          this.townOptions = [];
          this.villOptions = [];
          this.form.area = "";
          this.form.town = "";
          this.form.vill = "";
          break;
        case 4:
          this.townOptions = regionData;
          this.form.area = item.region_name;
          this.villOptions = [];
          this.form.town = "";
          this.form.vill = "";
          break;
        case 5:
          this.villOptions = regionData;
          this.form.town = item.region_name;
          this.form.vill = "";
          break;
        case 6:
          this.form.vill = item.region_name;
          break;
      }
    },
    //获取填写自有仓库的场地类型
    getTheVenueType(){
      axios.get("/pc/company-warehouse/allType").then((v) => {
        this.propertiesList = v.data.type_list;
      });
    },
    //获取区域的数据
    fetchRegionData (type, region_high_code) {
      let region_type = type.toString();
      return new Promise((resolve, reject) => {
        axios
          .get("/pc/region/all", { region_type, region_high_code })
          .then(response => {
            resolve(response.data.region_list);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    //选择场地类型
    handleCheckedProChange(value) {
      if (value){//选中
        //根据类型id设置类型名称
        for(let pro in this.propertiesList){
          for(let id in this.warehouse_type_ids){
            if (this.propertiesList[pro].id ==  this.warehouse_type_ids[id]) {
              this.warehouse_type_names[pro]=this.propertiesList[pro].label;
            }
          }
        }
      }else{//取消选中
           for(let pro in this.propertiesList){
             if (this.warehouse_type_ids.indexOf(this.propertiesList[pro].id)==-1){
               this.warehouse_type_names[pro] = null;
             }
           }
       }
      this.calculate(this.count,value,1);//计算场地类型是否选中
    },
    //选择储存环境
    handleCheckedStoChange(value){
      if (value) {//选中
        //根据类型id设置类型名称
        for (let sto in this.storages) {
          for (let id in this.storage_conditions_ids) {
            if (this.storages[sto].id == this.storage_conditions_ids[id]) {
              this.storage_conditions_names[sto] = this.storages[sto].lable;
            }
          }
        }
      }else{
        for(let sto in this.storages){
          if (this.storage_conditions_ids.indexOf(this.storages[sto].id)==-1){
            this.storage_conditions_names[sto] = null;
          }
        }
      }
      this.calculate(this.num,value,2);//计算存储类型是否选中

    },
    //用于记场地类型的数量
    calculate(count,value,type){
      if (value)
        count++;
      else
        count--;
      if (count>0&&type==1){
        this.form.warehouse_type_ids = this.warehouse_type_names.toString();
        this.count = count;
      }
      else if(count>0&&type==2){
        this.form.storage_conditions_ids = this.storage_conditions_ids.toString();
        this.num = count;
      }
      else if(count<=0&&type==1){
        this.form.warehouse_type_ids = "";
        this.count = count;
      }
      else if(count<=0&&type==2){
        this.form.storage_conditions_ids = "";
        this.num = count;
      }
    },
    //将数组转化为字符串
    array2String(){
      //去掉数组中的null
      this.warehouse_type_names = this.warehouse_type_names.filter(function (s) {
        return s && s.trim(); // 注：IE9(不包含IE9)以下的版本没有trim()方法
      });
      this.storage_conditions_names = this.storage_conditions_names.filter(function (s) {
        return s && s.trim(); // 注：IE9(不包含IE9)以下的版本没有trim()方法
      });
      //存储环境数组转化为字符串
      this.form.storage_conditions_names = this.storage_conditions_names.toString();
      this.form.storage_conditions_ids = this.storage_conditions_ids.toString();
      //场地类型数组转化为字符串
      this.form.warehouse_type_ids = this.warehouse_type_ids.toString();
      this.form.warehouse_type_names = this.warehouse_type_names.toString();
    },
    //将查寻出来的数据转化为表单中的信息
    json2Form(v){
      this.form = v;
      //将字符串转化为数组
      this.warehouse_type_ids = v.warehouse_type_ids.split(",");
      for(let i=0;i<this.warehouse_type_ids.length;i++){
        this.warehouse_type_ids[i] = parseInt(this.warehouse_type_ids[i]);
      }; //转化为int
      this.form.warehouse_type_ids = this.warehouse_type_ids.toString();
      this.storage_conditions_ids = v.storage_conditions_ids.split(",");
      this.form.storage_conditions_ids = this.storage_conditions_ids.toString();
      this.count = this.warehouse_type_ids.length;
      this.num = this.storage_conditions_ids.length;
    }
  },
};
</script>
<style lang="scss" scoped>
.shopList {
  overflow-x: hidden;
  .add {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .active {
    background: #2ec68a;
    border-color: #2ec68a;
    color: #ffffff;
  }
  .back_btn {
    background: rgba(247, 248, 249, 1);
    border: 1px solid rgba(217, 222, 225, 1);
  }
  .recordadd-title {
    height: 34px;
    line-height: 34px;
    border-left: 2px solid #788287;
  }
  /deep/ .el-input-group__append .el-button--primary .el-icon-search {
    color: #fff;
  }
  /deep/ .el-checkbox-button__inner {
    border-left: 1px solid #dcdfe6;
    border-radius: 4px !important;
  }
  /deep/ .el-table.no-header .el-table__header {
    display: none;
  }
  .people-message .el-form-item {
    margin-bottom: 0px;
  }
  .recordadd-upload {
    width: 160px;
    height: 160px;
    float: left;
    margin-right: 10px;
    .disabled {
      height: 100%;
    }
    /deep/ .el-upload {
      width: 160px;
      height: 160px;
      line-height: 160px;
    }
    /deep/ .el-upload-list--picture-card .el-upload-list__item {
      width: 160px;
      height: 160px;
    }
  }
  .health_url {
    /deep/ .el-upload--picture-card {
      width: 70px;
      height: 40px;
      background-color: transparent;
      border: none;
      .el-button--medium {
        position: absolute;
        left: 10px;
        margin-top: 4px;
      }
    }
    /deep/ .el-upload-list--picture-card .el-upload-list__item {
      width: 70px;
      height: 40px;
    }
  }
  /deep/ .el-col-7 .el-form-item__content {
    width: initial;
  }
  table .cell .is-error {
    padding-bottom: 20px;
  }
  .el-table th.is-leaf,
  .el-table td {
    border-bottom: none;
  }
  /deep/ .disabled .el-upload--picture-card {
    display: none !important;
  }
  /deep/ .el-table {
    .el-table__body,
    .el-table__header {
      width: 100% !important;
    }
  }
  .el-checkbox-button {
    margin-bottom: 10px;
  }
}
.form-block {
  border-top: 1px solid #eaeefb;
  height: 44px;
  box-sizing: border-box;
  background-color: #fff;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  text-align: center;
  margin-top: -1px;
  color: #d3dce6;
  cursor: pointer;
  position: relative;
}
.map {
  width: 100%;
  height: 300px;
}
.searchinput{
  width: 300px;
  box-sizing: border-box;
  padding: 9px;
  border: 1px solid #dddee1;
  line-height: 20px;
  font-size: 16px;
  height: 38px;
  color: #333;
  position: relative;
  border-radius: 4px;
}
</style>
